<template>
  <div>
    <vx-card>
      <div class="vx-row find-people">
        <!-- Intent leads table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title more-card-title">
            <h2 class="mb-3">Integration Partners</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. 
              Duis vulputate commodo lectus, ac blandit elit tincidunt id.
            </p>
          </div>
          <div class="common-table">
            <div class="flex btn-search w-full ml-4 justify-start">
              <router-link  :to="{name: 'StepWizard'}">
                <vs-button title="Update" color="primary" type="filled" icon-pack="feather" icon="icon-plus" class="mr-2 float-left">Add New</vs-button>
              </router-link>
            </div>
            <!-- table -->
            <vs-table :data="integrationPartners" search pagination>
              <template slot="thead">
                <vs-th>Partner</vs-th>
                <vs-th>Connection Name</vs-th>
                <vs-th>Scope</vs-th>
                <vs-th>Status </vs-th>
                <vs-th>Actions</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :data="tr"
                  :key="indextr"
                  v-for="(tr, indextr) in data" 
                  :class="data[indextr].Connected == false || data[indextr].StepToComplete != 0 ? 'text-danger' : 'text-success'" 
                >
                  <vs-td :data="data[indextr].Integration_Partner_Name"  >
                    <div class="flex items-center" @click="data[indextr].StepToComplete != 0?goToWizardStep(data[indextr].Connection_ID,data[indextr].StepToComplete):editWizard(data[indextr])">
                      <img :src="data[indextr].Integration_Partner_Logo" alt="Company Logo" class="inline-block mr-2" height="50" width="50" />
                      {{ data[indextr].Integration_Partner_Name }}
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].Connection_Name" >
                    <span @click="data[indextr].StepToComplete != 0?goToWizardStep(data[indextr].Connection_ID,data[indextr].StepToComplete):editWizard(data[indextr])">
                      {{ data[indextr].Connection_Name }}
                    </span>
                  </vs-td>
                  <vs-td :data="data[indextr].Scope_Name" >
                    <span @click="data[indextr].StepToComplete != 0?goToWizardStep(data[indextr].Connection_ID,data[indextr].StepToComplete):editWizard(data[indextr])">
                      {{ data[indextr].Scope_Name }}
                    </span>
                  </vs-td>
                  <vs-td :data="data[indextr].status" >
                    <span @click="data[indextr].StepToComplete != 0?goToWizardStep(data[indextr].Connection_ID,data[indextr].StepToComplete):editWizard(data[indextr])">
                      {{ data[indextr].Connected?"Connected":"Disconnected" }}
                    </span>
                  </vs-td>
                  <vs-td >
                    <div class="table-action w-full">
                      <vs-button title="Delete"  @click="deletePopup = true, deletableConnectionId = data[indextr].Connection_ID"  color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-2 my-1 float-right"></vs-button>
                      <router-link v-if="data[indextr].StepToComplete == 0"  :to="{name: 'IntegrationSetting',params: {integrationData : data[indextr]}}">
                        <vs-button title="Edit"  color="primary" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 my-1 float-right"></vs-button>
                      </router-link>
                      <vs-button v-if="data[indextr].Connected == false" @click="getExternalCRMLink(tr.Connection_ID)" title="Link"  color="warning" type="filled" icon-pack="feather" icon="icon-link" class="mr-2 my-1 float-right"></vs-button>
                      <vs-button v-if="data[indextr].StepToComplete != 0" title="Setting"  color="success" type="filled" icon-pack="feather" icon="icon-settings" @click="goToWizardStep(data[indextr].Connection_ID,data[indextr].StepToComplete)" class="mr-2 my-1 float-right"></vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vx-card>
    <!--delete Popup  -->
    <vs-popup class="delete-popup" :active.sync="deletePopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Are you sure you want to delete the record?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button title="Yes" color="danger" type="filled" class="mr-2" @click="deleteCRMIntegrationConnection()">{{ LabelConstant.buttonLabelYes }}</vs-button>
        <!-- <vs-button title="Yes" color="danger" type="filled" class="mr-2">Yes, Remove All</vs-button>
        <vs-button title="No" color="warning" type="filled" class="mr-2">No, Just Remove Partner</vs-button> -->
        <vs-button color="grey" @click="cancelDeleteConnection()" type="border" class="grey-btn mr-3">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
  </div> 
</template>

<script>

export default {
  data: () => ({
    deletePopup: false,
    setupwizardPopup: false,
    integrationPartners: [],
    isEventListner:false,
    activeConnectionId: null,
    deletableConnectionId: null,
    uid: null,
  }),
  mounted() {
    var that = this;
    window.addEventListener("focus", checkCRMConnectionStatus);

    function checkCRMConnectionStatus() {
        if (that.isEventListner) {
            that.getIntegrationPartners();
        }
        that.isEventListner = false;
    }
  },
  created() {
    this.uid = this.$route.params.id;
    this.getIntegrationPartners();
  },
  methods: {
    async getIntegrationPartners() {
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartners/GetIntegrationPartners").then(response => {
        let data = response.data;
        this.integrationPartners = data;
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    cancelDeleteConnection(){
      this.deletePopup = false
      this.deletableConnectionId = null
    },
    deleteCRMIntegrationConnection(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationPartners/DeleteIntegrationPartner?connection_Id="+this.deletableConnectionId).then(() => {
        this.cancelDeleteConnection()
        this.getIntegrationPartners()
        this.$vs.notify({
          title: "Success",
          text: "Connection deleted!",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000,
        });
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    async getExternalCRMLink(id){
      this.$vs.loading();
      await this.axios.get("/ws/IntegrationPartnerConnection/GetExternalLinkForAuthUpdate?connection_Id="+id).then(response => {
        let data = response.data;
        this.activeConnectionId = id;
        this.isEventListner = true;
        window.open(data,+"Zoho Account","toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100").focus();
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    goToWizardStep(id,step){
      let VVtoken = localStorage.getItem("VVtoken");
      let token = JSON.parse(VVtoken);
      token[this.uid].completeStep = step;
      token[this.uid].wizardConnectionId = id;
      localStorage.setItem("VVtoken", JSON.stringify(token));
      this.$router.push({ name: 'StepWizard' })
    },
    editWizard(data){
      this.$router.push({name: 'IntegrationSetting',params: {integrationData : data}})
    }
  },
};
</script>